import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import NewsView from '../views/NewsView.vue'
import CompanyNewsView from '../views/CompanyNewsView.vue'




const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: IndexView
      // component: () => import('../views/IndexView.vue')
    },
    {
      path: '/news',
      name: 'news',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: NewsView
      // component: () => import('../views/NewsView.vue')
    },
    {
      path: '/detail/:id',
      name: 'detail',
      // component: DetailView
      component: () => import('../views/DetailView.vue')
    },
    {
      path: '/cnews',
      name: 'cnews',
      component: CompanyNewsView
      // component: () => import('../views/CompanyNewsView.vue')
    },
    {
      path: '/cdetail/:id',
      name: 'cdetail',
      // component: DetailView
      component: () => import('../views/CompanyDetailView.vue')
    },
    {
      path: '/show/:id',
      name: 'show',
      // component: DetailView
      component: () => import('../views/ShowView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/about2',
      name: 'about2',
      component: () => import('../views/AboutView2.vue')
    },
    {
      path: '/wzsm',
      name: 'wzsm',
      component: () => import('../views/WzsmView.vue')
    }
  ]
})

export default router
