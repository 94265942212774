<script setup>
// defineProps<{
//    show: Boolean,
// }>()
defineProps({
   show: Boolean,
});
const emit = defineEmits(['myClose'])

</script>

<template>
   <Teleport to="body">
      <div class="wx_popup" :class="{'show':show}" @click="emit('myClose',false)">
         <div class="imgbox">
            <!-- <img src="../assets/icon_close.png" /> -->
            <img src="../assets/tcb_wx.jpg" />
            <div class="tips">点击任意位置关闭</div>
         </div>
      </div>
   </Teleport>
</template>

<style scoped>
.wx_popup{visibility: hidden; position: fixed; top:0; bottom:0; left:0; right:0; background: rgba(0,0,0,0); display:flex; transition-duration: 300ms;}
.wx_popup.show{visibility: visible; background: rgba(0,0,0,0.6);}
.wx_popup .imgbox{width:250px; height:250px; margin:auto;}
.wx_popup .imgbox img{width:260px; height:260px; opacity:0;}
.wx_popup .imgbox .tips{color:#fff; text-align: center; opacity:.9; font-size:16px;}
.wx_popup.show .imgbox img{width:260px; height:260px; opacity:1;}

@media (min-width: 1024px) {

}
</style>
