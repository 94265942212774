<script setup>
import {ref,watch} from 'vue'
import { mapGetters, storeToRefs } from 'pinia'
import { useCounterStore } from '@/stores/counter'
import SearchIcon from './icons/IconSearch.vue'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
// defineProps<{
//   msg: {type:any, default:''},
// }>()
defineProps({
    msg: String,
});

const store = useCounterStore()
const { year } = storeToRefs(store) 

const emit = defineEmits(['mySearch','clearSearch'])

const keyword = ref('')
const clearSearch = ()=>{
  keyword.value = ''
  emit('clearSearch',keyword.value)
}
const selectChange = (e)=>{
  console.log(e.target.value)
  store.setYear(e.target.value)
}

const search = (e)=>{
  e.preventDefault()
  emit('mySearch',keyword.value)
  return false;
}

</script>

<template>
  <form @submit="search">
      <div class="input-box">
          <input v-model="keyword" :placeholder="locale==='zh'?'请输入关键字搜索':'Search Based on Keyword'" />
          <span id="clearSearch" class="clear" v-if="keyword!=''" @click="clearSearch">{{ locale==='zh'?'清除':'Clear' }}</span>
          <SearchIcon @click="search" class="icon-search" style="width:20px; height:20px;" />
      </div>
    </form>
</template>

<style scoped>
form{display: flex; justify-content: space-between; width:273px; }
.input-box{height:33px; width:273px; border:1px solid #ccc; display: flex; justify-content: space-between;}
.input-box input{font-size:13px; color:#666; height:32px; line-height:22px; border:none; padding:5px; flex:1 1 auto; outline:none; background:none;}
.input-box .clear{font-size:12px; color:#666; display:block; height:22px; line-height:22px; background:#eee; margin-top:4px; padding:0 5px; border-radius: 5px; cursor:pointer;}
.input-box .icon-search{padding:5px; box-sizing: content-box; cursor: pointer;}

@media (min-width: 1024px) {

}
</style>
