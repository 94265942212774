<script setup>
import {ref,watch,onMounted,computed} from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterLink} from 'vue-router'
import { useCounterStore } from '@/stores/counter'
import SearchBox from '../components/SearchBox.vue'
import Ad from '../components/Ad.vue'
// import {getRankingList} from '@/api/index';

const rawList = ref([{company_id: "",company_name: "",company_name_en: "",country: "",country_en: "",listed: "",product: "",product_en: "",total_revenue: ""}]);
const list = ref([{company_id: "",company_name: "",company_name_en: "",country: "",country_en: "",listed: "",product: "",product_en: "",total_revenue: ""}]);
const store = useCounterStore()
const { locale } = useI18n()

// const { count } = store 
// 代替解构赋值，直接解构赋值，属性会失去响应式
const { year,typeid,ranklist } = storeToRefs(store) 

const keyword = ref('')
const search = (e)=>{
   console.log(e)
   let searchList = [];
   keyword.value = e;
   rawList.value.forEach((item)=>{
      console.log(item.company_name)
      if(item.company_name.toLowerCase().indexOf(e.toLowerCase())!==-1||item.company_name_en.toLowerCase().indexOf(e.toLowerCase())!==-1){
         console.log(item)
         searchList.push(item)
      }
   })
   if(searchList.length===0){
      alert("暂无搜索结果！")
      document.getElementById("clearSearch").click()
      clearSearch()
   }else{
      list.value = searchList
   }
}
const clearSearch = ()=>{
   keyword.value = '';
   list.value = rawList.value;
}

const getList = async ()=>{
   console.log('ranklist:',ranklist.value)
   if(ranklist.value.length===0){
      console.log("store中没有数据，请求数据")
      let res = await store.GetRankingList()
      rawList.value = res
      list.value = res
   }else{
      console.log("store中有数据，直接赋值")
      rawList.value = ranklist.value
      list.value = ranklist.value
   }
}
getList();
//监控路由变化
watch(
   () => [year.value,typeid.value],
   async (newYear,newTypeId) => {
      console.log('watch year typeid',newYear,newTypeId)
      keyword.value = '';
      list.value = rawList.value;
      // document.getElementById("clearSearch").click()
      getList();
   }
)


// const getList = ()=>{
//    getRankingList({ranking_year:year.value, type_id:typeid.value}).then(res=>{
//       console.log(res)
//       rawList.value = res.data
//       list.value = res.data
//    })
// }
// getList();
// //监控路由变化
// watch(
//    () => [year.value,typeid.value],
//    async (newYear,newTypeId) => {
//       console.log('watch year typeid',newYear,newTypeId)
//       keyword.value = '';
//       list.value = rawList.value;
//       // document.getElementById("clearSearch").click()
//       getList();
//    }
// )

const numFormat = computed(()=>{
   return (num)=>{
      if(num==="※"){
         return num;
      }
      if(locale.value==='en'){
         if(year.value==2021){
            return ((num*1)/6.37*100).toFixed(1)
         }
         if(year.value==2022){
            return ((num*1)/6.96*100).toFixed(1)
         }
      }
      return (num*1).toFixed(1)
   }
})
const areaFormat = computed(()=>{
   return (num)=>{
      if(num==="※"){
         return num;
      }
      if(locale.value==='en'){
         return Math.round(num*1*100)
      }
      return num;
   }
})

</script>

<template>
  <div class="page-index" :class="{'en':locale==='en'}">
    <div class="wrap">
      <div class="flexbox">
         <div class="left mobile-hide" v-if="typeid===1">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【世界陶瓷卫浴100强】以企业总营收进行排名，统计对象涵盖全球陶瓷企业、卫浴企业、陶瓷装备与材料企业；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈等方式，对数据可靠性进行核实。此外，了解到行业内有不少“隐形冠军”，但由于缺乏以上数据来源的支持，从严格执行编写的原则考虑，列出关注企业名单，其排名数字统一标注为 ※ ，我们持续关注这些企业的发展。</p>
                  <p>3、统计原则：客观、公正、科学、严谨；</p>
                  <p>4、统计方式：每年统计一次；</p>
                  <p>5、各国的货币按统计年份当年12月31日中国人民银行当天公布的汇率统一折算的人民币或美元进行统计。所有上市公司的数据均来自统计年份的财务年报（个别公司数据来自申请IPO招股书）。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by total revenue of companies, covering global ceramic companies, sanitary ware companies, ceramic equipment and materials companies</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on actual needs, combined with on-site inspections, industry interviews, etc</p>
                  <P>3、Statistical principles: objective, fair, scientific, and rigorous</P>
                  <P>4、Statistical method: annually</P>
                  <P>5、The currencies of various countries are calculated based on the exchange rates published by the People's Bank of China on December 31 of the same year, which are uniformly converted into RMB or US dollars. All data of listed companies are sourced from the financial annual report of the statistical year (some companies' data are sourced from the IPO prospectus).</P>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===2">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【全球陶瓷30强】榜单以陶瓷销售额作为排名依据；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
                  <P>3、此外，了解到行业内有不少“隐形冠军”，但由于缺乏数据来源的支持，从严格执行编写的原则考虑，列入关注企业但不参与本榜单排名，我们持续关注这些企业的发展。</P>
                  <p>4、各国的货币按统计年份当年12月31日中国人民银行当天公布的汇率统一折算的人民币或美元进行统计。所有上市公司的数据均来自统计年份的财务年报（个别公司数据来自申请IPO招股书）。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by revenue of ceramic segment</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
                  <P>3、In addition, we have known that there are many "invisible champions" in the industry, but due to the lack of support from the above data sources, we have strictly followed the principle and listed the following companies, with their ranking uniformly marked as ※. We continue to monitor the development of these companies.</P>
                  <p>4、The currencies of various countries are calculated based on the exchange rates published by the People's Bank of China on December 31 of the same year, which are uniformly converted into RMB or US dollars. All data of listed companies are sourced from the financial annual report of the statistical year (some companies' data are sourced from the IPO prospectus).</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===3">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【全球卫浴30强】榜单以卫浴销售额作为排名依据；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
                  <P>3、此外，了解到行业内有不少“隐形冠军”，但由于缺乏数据来源的支持，从严格执行编写的原则考虑，列入关注企业但不参与本榜单排名，我们持续关注这些企业的发展。</P>
                  <p>4、各国的货币按统计年份当年12月31日中国人民银行当天公布的汇率统一折算的人民币或美元进行统计。所有上市公司的数据均来自统计年份的财务年报（个别公司数据来自申请IPO招股书）。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by revenue of sanitary ware segment</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
                  <P>3、In addition, we have known that there are many "invisible champions" in the industry, but due to the lack of support from the above data sources, we have strictly followed the principle and listed the following companies, with their ranking uniformly marked as ※. We continue to monitor the development of these companies.</P>
                  <p>4、The currencies of various countries are calculated based on the exchange rates published by the People's Bank of China on December 31 of the same year, which are uniformly converted into RMB or US dollars. All data of listed companies are sourced from the financial annual report of the statistical year (some companies' data are sourced from the IPO prospectus).</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===4">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【陶瓷装备和材料企业15强】榜单以装备和材料企业销售额作为排名依据；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
                  <P>3、此外，了解到行业内有不少“隐形冠军”，但由于缺乏数据来源的支持，从严格执行编写的原则考虑，列入关注企业但不参与本榜单排名，我们持续关注这些企业的发展。</P>
                  <p>4、各国的货币按统计年份当年12月31日中国人民银行当天公布的汇率统一折算的人民币或美元进行统计。所有上市公司的数据均来自统计年份的财务年报（个别公司数据来自申请IPO招股书）。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by revenue of equipment and materials companies</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
                  <P>3、In addition, we have known that there are many "invisible champions" in the industry, but due to the lack of support from the above data sources, we have strictly followed the principle and listed the following companies, with their ranking uniformly marked as ※. We continue to monitor the development of these companies.</P>
                  <p>4、The currencies of various countries are calculated based on the exchange rates published by the People's Bank of China on December 31 of the same year, which are uniformly converted into RMB or US dollars. All data of listed companies are sourced from the financial annual report of the statistical year (some companies' data are sourced from the IPO prospectus).</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===5">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【全球陶瓷产量30强】榜单以企业实际产量作为排名依据；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by actual production</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===6">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【陶瓷产能30强（中国）】榜单以企业拥有的陶瓷生产线及产能作为依据排名；</P>
                  <p>2、据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by the ceramic production lines and production capacity</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="left mobile-hide" v-if="typeid===7">
            <div class="about-box" v-if="locale==='zh'">
               <h4>统计排行原则及说明</h4>
               <div class="content">
                  <P>1、排名依据：【陶瓷单一品牌30强（中国）】榜单以陶瓷单一品牌的销售规模为依据排名；</P>
                  <p>2、数据来源：一是上市企业公开数据；二是企业自报数据，并通过现场考察、行业访谈、发函询证等方式，对数据可靠性进行核实。</p>
               </div>
               <Ad />
            </div>
            <div class="about-box" v-if="locale==='en'">
               <h4>Principles and Explanation of The Rankings</h4>
               <div class="content">
                  <P>1、Ranking by sales of the single brand</P>
                  <p>2、Data Source: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on a site visit, actual needs, combined with inquiry on invitation letter, industry interviews, etc</p>
               </div>
               <Ad />
            </div>
         </div>
         <div class="right">
            <div class="titlebox">
               <h2>
                  <span v-if="typeid=='1'">{{ locale==='zh'?'世界陶瓷卫浴100强':'Global 100' }}</span>
                  <span v-if="typeid=='2'">{{ locale==='zh'?'全球陶瓷30强':'Ceramics Global 30' }}</span>
                  <span v-if="typeid=='3'">{{ locale==='zh'?'全球卫浴30强':'Sanitary Ware Global 30' }}</span>
                  <span v-if="typeid=='4'">{{ locale==='zh'?'陶瓷装备和材料企业15强':'Equipment and Materials Global 15' }}</span>
                  <span v-if="typeid=='5'">{{ locale==='zh'?'全球陶瓷产量30强':'Production Global 30' }}</span>
                  <span v-if="typeid=='6'">{{ locale==='zh'?'陶瓷产能30强':'Production Capacity Top 30(China)' }}</span>
                  <span v-if="typeid=='7'">{{ locale==='zh'?'陶瓷单一品牌30强':'Single Brand Top 30(China)' }}</span>
                  <i v-if="locale==='zh'" style="font-style: normal;">({{year}}财年)</i>
                  <i v-if="locale==='en'" style="font-style: normal;">(Fiscal Year {{year}})</i>
               </h2>

               

               <h5 v-if="locale==='zh'&&year!=2021&&[1,2,3,4,7].indexOf(typeid)!==-1">
                  <span>排名依据：{{year}}年全年营收，</span>
                  <span>发布时间：{{year*1+1}}年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year!=2021&&[1,2,3,4,7].indexOf(typeid)!==-1">
                  <span>Ranking By：Total Revenue in {{year}}, </span>
                  <span>Release Date：October {{year*1+1}}</span>
               </h5>
               <h5 v-if="locale==='zh'&&year!=2021&&typeid===5">
                  <span>排名依据：{{year}}年企业瓷砖产量，</span>
                  <span>发布时间：{{year*1+1}}年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year!=2021&&typeid===5">
                  <span>Ranking By：Production Of Ceramics in {{year}}, </span>
                  <span>Release Date：October {{year*1+1}}</span>
               </h5>
               <h5 v-if="locale==='zh'&&year!=2021&&typeid===6">
                  <span>排名依据：{{year}}年企业瓷砖年产能，</span>
                  <span>发布时间：{{year*1+1}}年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year!=2021&&typeid===6">
                  <span>Ranking By：Annual ProductionCapacity Of Ceramics in {{year}}, </span>
                  <span>Release Date：October {{year*1+1}}</span>
               </h5>
               <h5 v-if="locale==='zh'&&year==2021">
                  <span>排名依据：2021年全年营收，</span>
                  <span>发布时间：2022年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year==2021">
                  <span>Ranking By：Total Revenue in 2021, </span>
                  <span>Release Date：October 2022</span>
               </h5>



               <!-- <h5 v-if="locale==='zh'&&year==2022&&[1,2,3,4,7].indexOf(typeid)!==-1">
                  <span>排名依据：2022年全年营收，</span>
                  <span>发布时间：2023年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year==2022&&[1,2,3,4,7].indexOf(typeid)!==-1">
                  <span>Ranking By：Total Revenue in 2022, </span>
                  <span>Release Date：October 2023</span>
               </h5>
               <h5 v-if="locale==='zh'&&year==2022&&typeid===5">
                  <span>排名依据：2022年企业瓷砖产量，</span>
                  <span>发布时间：2023年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year==2022&&typeid===5">
                  <span>Ranking By：Production Of Ceramics in 2022, </span>
                  <span>Release Date：October 2023</span>
               </h5>
               <h5 v-if="locale==='zh'&&year==2022&&typeid===6">
                  <span>排名依据：2022年企业瓷砖年产能，</span>
                  <span>发布时间：2023年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year==2022&&typeid===6">
                  <span>Ranking By：Annual ProductionCapacity Of Ceramics in 2022, </span>
                  <span>Release Date：October 2023</span>
               </h5>
               <h5 v-if="locale==='zh'&&year==2021">
                  <span>排名依据：2021年全年营收，</span>
                  <span>发布时间：2022年10月</span>
               </h5>
               <h5 v-if="locale==='en'&&year==2021">
                  <span>Ranking By：Total Revenue in 2021, </span>
                  <span>Release Date：October 2022</span>
               </h5> -->

               
               <SearchBox :msg="keyword" @my-search="search" @clear-search="clearSearch"></SearchBox>
            </div>
            <div>
               <h4></h4>
               <table class="mobile-hide">
                  <tr>
                     <th style="width: 8%;" v-if="typeid!='6'">{{ locale==='zh'?'排名':'Ranking' }}</th>
                     <th style="width: 42%;">{{ locale==='zh'?'企业':'Company' }}</th>
                     <!-- <th style="width: 10%;" v-if="[1,2,3,5].indexOf(typeid)!==-1">{{ locale==='zh'?'营收(亿元)':'Revenue' }}</th> -->
                     <th style="width: 15%;" v-if="typeid===1">{{ locale==='zh'?'公司营收 (亿元)':'Revenue(mill. $)' }}</th>
                     <th style="width: 15%;" v-if="typeid===2">{{ locale==='zh'?'瓷砖营收 (亿元)':'Revenue of Ceramic Segment(mill. $)' }}</th>
                     <th style="width: 15%;" v-if="typeid===3">{{ locale==='zh'?'卫浴营收 (亿元)':'Revenue of Sanitary Ware Segment(mill. $)' }}</th>
                     <th style="width: 15%;" v-if="typeid===5" v-html="locale==='zh'?'产量 (亿平方米)':'Production(mll. sqm.)'"></th>
                     <th style="width: 14%;">{{ locale==='zh'?'主营':'Main products' }}</th>
                     <th style="width: 10%;">{{ locale==='zh'?'国家':'Country' }}</th>
                     <th style="width: 8%;">{{ locale==='zh'?'上市':'Listing' }}</th>
                  </tr>
                  <tr v-for="(item,index) in list" :key="index">
                     <td v-if="typeid!='6'">{{item.ranking_number}}</td>
                     <td v-if="locale==='zh'"><RouterLink :to="'/show/'+item.company_id">{{item.company_name}}<span v-if="item.company_name_en!==''">({{item.company_name_en}})</span></RouterLink></td>
                     <td v-if="locale==='en'"><RouterLink :to="'/show/'+item.company_id">{{item.company_name_en}}</RouterLink></td>

                     <td v-if="[1,2,3].indexOf(typeid)!==-1">{{numFormat(item.total_revenue)}}</td>
                     <td v-if="[5].indexOf(typeid)!==-1">{{areaFormat(item.total_revenue)}}</td>

                     <td v-if="locale==='zh'">
                        <!-- <div style="line-height: 18px;" v-for="(subitem,subindex) in item.product.split(',')" :key="subindex">{{ subitem }}</div> -->
                        {{ item.product }}
                     </td>
                     <td v-if="locale==='en'">
                        <div style="line-height: 18px;" v-for="(subitem,subindex) in item.product_en.split(',')" :key="subindex">{{ subitem }}</div>
                     </td>
                     <td>{{ locale==='zh'?item.country:item.country_en }}</td>
                     <td v-if="locale==='zh'">{{ item.listed==1?"是":"" }}{{ item.listed==0?"未上市":"" }}{{ item.listed==2?"IPO":"" }}</td>
                     <td v-if="locale==='en'">{{ item.listed==1?"YES":"" }}{{ item.listed==0?"NO":"" }}{{ item.listed==2?"IPO":"" }}</td>
                  </tr>
               </table>
               <table class="pc-hide">
                  <tr>
                     <th style="width: 14%;" v-if="typeid!='6'">{{ locale==='zh'?'排名':'Ranking' }}</th>
                     <th style="width: 52%;">{{ locale==='zh'?'企业':'Company' }}</th>
                     <th style="width: 16%;" v-if="typeid===1">{{ locale==='zh'?'公司营收 (亿元)':'Revenue(mill. $)' }}</th>
                     <th style="width: 16%;" v-if="typeid===2">{{ locale==='zh'?'瓷砖营收 (亿元)':'Revenue of Ceramic Segment(mill. $)' }}</th>
                     <th style="width: 16%;" v-if="typeid===3">{{ locale==='zh'?'卫浴营收 (亿元)':'Revenue of Sanitary Ware Segment(mill. $)' }}</th>
                     <th style="width: 16%;" v-if="typeid===5" v-html="locale==='zh'?'产量 (亿平方米)':'Production(mll. sqm.)'"></th>
                     <th style="width: 18%;">{{ locale==='zh'?'国家':'Country' }}</th>
                  </tr>
                  <tr v-for="(item,index) in list" :key="index">
                     <td v-if="typeid!='6'">{{item.ranking_number}}</td>
                     <td>
                        <RouterLink :to="'/show/'+item.company_id" style="display: block;">
                        <span v-if="locale==='zh'">{{item.company_name}}<br /></span>
                        {{item.company_name_en}}
                        </RouterLink>
                     </td>
                     <td v-if="[1,2,3].indexOf(typeid)!==-1">{{numFormat(item.total_revenue)}}</td>
                     <td v-if="[5].indexOf(typeid)!==-1">{{areaFormat(item.total_revenue)}}</td>

                     <td>{{ locale==='zh'?item.country:item.country_en }}</td>
                  </tr>
               </table>
            </div>
        </div>

       
         <!-- <div class="about-box pc-hide" v-if="locale==='zh'">
            <h4>统计排行原则及说明</h4>
            <div class="content">
               <P>1、100强统计排行榜的统计对象包括陶瓷、 卫浴企业, 统计排名主要的企业的营收规模作为依据.</P>
               <p>2、报告数据来源有三: 一是上市企业公开数据; 二是有一定公开资料 (如纳税大户公告) 支持下的调研和分折测算; 三是企业自报数据, 我们了解到行业内有不少'隐形冠军" ,但由于缺泛以上三点数据来源的支持, 从严格执行编写的原则专虑, 本报告中暂不统计这类企业</p>
               <P>3、各国的貨市投2021年12月31日中国人民银行当天公布的汇率统一折算的人民市或美元进行统计. 所有上市公司的数据均來自2021年度年报 (个别公司数据米自申清IPO招股书)</P>
            </div>
            <img src="https://www.uniceramics.com.cn/img/s50102.da5001ff.jpg" />
         </div>
         <div class="about-box pc-hide" v-if="locale==='en'">
            <h4>Principles and Explanation of The Rankings</h4>
            <div class="content">
                  <P>1、The statistical objects of the Global 100 included ceramic and sanitary ware companies, and the revenue is used as the main basis for statistics</P>
                  <p>2、There are three main data sources: Public data of listed companies and data submitted independently by companies. The organizer will also further verify the reliability of the data based on actual needs, combined with on-site inspections, industry interviews, etc. We have learned that there are many 'invisible champions' in the industry, but due to the lack of support from the three data sources mentioned above, we are considering strictly following the principle. Therefore, this report will not include such companies for the time being.</p>
                  <P>3、On December 31, 2022, the exchange rates announced by the People's Bank of China on the day of trading in various countries were converted into the Yuan or US dollars for statistical purposes. All data of listed companies were sourced from the 2022 annual report (some companies' data were sourced from the Shenqing IPO prospectus)</P>
            </div>
            <img src="https://www.uniceramics.com.cn/img/s50102.da5001ff.jpg" />
         </div> -->
   
      </div>

      <!-- <div>i18n: {{ t('message.hello') }}</div>
      <div>首页首546546页</div>
      <div>storeToRefs: {{ count }}</div>
      <div>store.count: {{ store.count }}</div>
      <div @click="store.increment()">点击+1</div>
      <RouterLink to="/detail/123">goto详情页</RouterLink> -->
    </div>
  </div>
</template>

<style scoped>
.page-index{width:100vw; background:#fff; min-height:calc( 100vh - 225px ); padding-bottom:30px;}
.page-index .wrap{width:1100px; margin:auto;}
.page-index.en .wrap{width:1100px;}

.page-index h4{line-height:15px; border-bottom:1px solid #ccc; font-size:13px; color:#111; margin-bottom:20px; padding-bottom:8px;}

.page-index .flexbox{display:flex; justify-content: space-between;}
.page-index .right .titlebox h2{font-size:37px; line-height:37px; color:#333; margin:36px 0 10px 0; font-weight: bold; text-align: center;}
.page-index .right .titlebox h2 span{font-weight: bold;}
.page-index .right .titlebox h5{font-size:16px; line-height:20px; color:#666; margin-bottom:20px; text-align:center;}
.page-index .right form{margin:0 auto;}

.page-index.en table{width:800px;}
table{width:800px;}
table.mobile-hide{display: table;}
table tr th{background:#eaeaea; text-align: left;}
table tr td{padding:8px 0; border-bottom: 1px solid #ccc; font-size:14px; text-align:left; color:#333;}
/* table tr td:nth-of-type(2){text-align: left;} */
table tr td a{color:#042246;}
table tr td a:hover{text-decoration: underline; color:#013678; background:none;}
table tr{transition-duration: 300ms;}
table tr:hover{background:#eaeaea;}

.about-box{width:248px; font-size:13px; line-height: 23px; padding-top:142px;}
.about-box .adbox{margin-top:30px;}
.about-box p{color:#333;}

.page-index.en .right .titlebox h2{font-size: 32px;}

@media (max-width: 600px) {
.page-index{width:100%;}
.page-index.en{width:100%;}
.page-index .wrap{width:100%; padding:0 15px;}
.page-index.en .wrap{width:100%; padding:0 15px;}

.page-index .flexbox{display:block;}
.page-index .right h4{border:none; height:0px;}
.page-index .right .titlebox h2{font-size:27px;}
.page-index .right .titlebox h5{font-size:14px; line-height:14px; color:#666; margin-bottom:20px; text-align:center;}
table.pc-hide{width:100%; display: table;}
.page-index.en table{width:100%;}
table.mobile-hide{display: none;}
table tr th{color:#333; font-size: 13px;}
/* table tr th:nth-of-type(2){text-align: left;} */
table tr{width:100%;}
table tr td{padding:10px 0; font-size: 13px;}
table tr td a{line-height:18px; font-size: 13px;}

.about-box{width: 384px; padding-top:48px; font-size:14px; line-height: 26px;} 
.about-box h4{font-size:18px; height:35px;}
.about-box .adbox{margin-top:30px;}
}
</style>