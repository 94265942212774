<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

</script>


<template>
<footer>
    <div class="wrap" v-if="locale==='zh'">
        <div>
            <div class="abox">
                <RouterLink to="/about">关于我们</RouterLink><span>|</span>
                <RouterLink to="/about2">关于榜单</RouterLink><span>|</span>
                <RouterLink to="/wzsm">网站声明</RouterLink></div>
            <div class="email">
                <span>合作热线：0757-88038633</span><br class="pc-hide" />
                <span>邮箱：info@ceramicglobal100.com</span><br class="pc-hide" />
                <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备13055433号</a>
            </div>
        </div>
        <div class="weixin-box">
            <span class="mobile-hide">关注微信</span>
            <img src="../assets/tcb_wx.jpg" />
            <span class="pc-hide">关注微信</span>
        </div>
    </div>
    <div class="wrap" v-if="locale==='en'">
        <div>
            <div class="abox">
                <RouterLink to="/about">About us</RouterLink><span>|</span>
                <RouterLink to="/about2">About listing</RouterLink><span>|</span>
                <RouterLink to="/wzsm">Website Statement</RouterLink></div>
            <div class="email">
                <span>Cooperation Hotline：0757-88038633</span><br class="pc-hide" />
                <span>Email：info@ceramicglobal100.com</span><br class="pc-hide" />
                <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备13055433号</a>
            </div>
        </div>
        <div class="follow-us" v-if="locale==='en'">
            <div>
                <a href="https://twitter.com/CeramicTWeekly" target="_blank"><img src="../assets/icon-t.png" /></a>
                <a href="https://www.facebook.com/ceramictownweekly" target="_blank"><img src="../assets/icon-yg.png" /></a>
                <a href="https://www.youtube.com/channel/UCuRPCkjpt4trT9Fnoe0B60A" target="_blank"><img src="../assets/icon-facebook.png" /></a>
            </div>
            <div class="tips">Follow Us</div>
        </div>
    </div>
</footer>
</template>

<style scoped>
footer{background: #032345; color:#fff; width:100vw; height:108px; padding-top:25px;}
footer .wrap{width:1100px; margin:0 auto; display:flex; justify-content: space-between; align-items: flex-end; font-size:13px;}
footer .wrap .abox{margin-bottom:10px;}
footer .wrap .abox a{color:#fff;}
footer .wrap .abox span{margin:0 10px;}
footer .wrap .email span{margin:0 10px 0 0;}
footer .wrap .email a{color:#fff; text-decoration: underline;}
.weixin-box{display:flex; align-items: flex-end;}
.weixin-box span{font-size:13px; line-height:15px; margin-right:15px;}
.weixin-box>img{width:60px; height:60px;}

.follow-us{display: block; text-align: center;}
.follow-us a img{width: 40px; height:40px;}
.follow-us .tips{margin-top: -10px;}


@media (max-width: 600px) {
footer{height:148px;}
footer .wrap{width:414px; padding:0 15px; align-items: flex-end; font-size:14px;}
footer .wrap .email span{line-height: 24px;}
.weixin-box{display:flex; flex-direction: column;}
.weixin-box img{width:80px; height:80px; margin-bottom:5px;}

.follow-us{display: none; text-align: center;}
.follow-us a img{width: 25px; height:25px;}
.follow-us .tips{margin-top: -10px;}
}
</style>
