<script setup>
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
defineProps({
    width: {type:String, default:'248px'},
});

</script>

<template>
    <div class="adbox" :style={width:width}>
        <a target="_blank" href="https://www.uniceramicsexpo.com/"><img :style={width:width} v-if="locale==='en'" src="../assets/ad01.gif" /></a>
        <a target="_blank" href="https://www.uniceramics.com.cn/"><img :style={width:width} v-if="locale==='zh'" src="../assets/ad02.gif" /></a>
    </div>
</template>

<style scoped>
img{width:248px;}
@media (max-width: 600px) {
    img{width:384px!important;}
}
</style>
