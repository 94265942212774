import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {getRankingList} from '@/api/index';


export const useCounterStore = defineStore('counter', () => {
  // const count = ref(0)
  // const doubleCount = computed(() => count.value * 2)
  // function increment() {
  //   count.value++
  // }

  const year =  ref(localStorage.year || '2023');
  function setYear(y){
    ranklist.value = [];
    year.value = y
    localStorage.setItem("year",y)
  }

  const typeid =  ref(localStorage.typeid*1 || 1);
  function setTypeId(y){
    ranklist.value = [];
    typeid.value = y
    localStorage.setItem("typeid",y)
  }

  //排行榜相关数据
  const ranklist = ref([]);
  async function GetRankingList(){
    try {
      let res = await getRankingList({ranking_year:year.value, type_id:typeid.value})
      ranklist.value = res.data;
      return res.data;
    } catch (error) {
      console.log(error)
      return error
    }
  }

  return { year,setYear,typeid,setTypeId,ranklist,GetRankingList }
})
