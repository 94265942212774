import './assets/main.css'
import { createApp} from 'vue'
import { createPinia } from 'pinia'
// import { createI18n } from 'vue-i18n'
import i18n from '@/vueI18n' //引入国际化

import App from '@/App.vue'
import router from '@/router'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.mount('#app')

// mounted(){
//     setTimeout(()=>{
//         document.dispatchEvent(new Event('render-event')) //这里的名称和上面的名称保持一直
//     },5000)
// }

setTimeout(()=>{
    document.dispatchEvent(new Event('custom-render-trigger'))
},2000)

