import request from './request'

export function getRankingList(params) {
  return request({
    url: '/ranking/list',
    method: 'post',
    data: params
  })
}

export function getCompanyById(id) {
  return request({
    url: '/company/'+id,
    method: 'get',
  })
}

export function getNewsList(params) {
  return request({
    url: '/news/list',
    method: 'post',
    data: params
  })
}

export function getNewsById(id) {
  return request({
    url: '/news/'+id,
    method: 'get',
  })
}

// 6. 企业新闻列表
export function getCompanyNewsList(params) {
  return request({
    url: '/company/news/list',
    method: 'post',
    data: params
  })
}

export function getCompanyNewsById(id) {
  return request({
    url: '/company/news/'+id,
    method: 'get',
  })
}