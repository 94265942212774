// locale/index.ts
import { createI18n } from "vue-i18n";
// 导入全局中英文配置对象
import en from './en'
import zh from './zh'
 
function getBrowserLang() {
  let browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
  let defaultBrowserLang = ''
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'zh'
  } else {
    defaultBrowserLang = 'en'
  }
  return defaultBrowserLang
}

let defaultLang = ''
if(getBrowserLang()==='zh'){
  defaultLang = 'zh'
}else{
  defaultLang = 'en'
}
console.log(getBrowserLang())

// 默认读取本地存储语言设置
const defaultLocale = localStorage.getItem('locale') || defaultLang;

const i18n = createI18n({
  locale: defaultLocale,// 默认语言
  fallbackLocale: 'zh',// 不存在默认则为英文
  allowComposition: true,// 允许组合式api
  messages: {
    'en': en, // 标识:配置对象
    'zh': zh
  },
})
export default i18n