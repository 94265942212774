<script setup>
// import {ref} from 'vue'
const scroppToTop = ()=>{
   document.body.scrollTop=document.documentElement.scrollTop=0
}

// onMounted(() => {
//    // window.addEventListener("scroll",handleScroll);
//    // totop.value.style.left = (1000+(document.body.clientWidth-1000)/2+20)+'px'
//    // window.addEventListener("resize",()=>{
//    //    totop.value.style.left = (1000+(document.body.clientWidth-1000)/2+30)+'px'
//    // });
// })
</script>

<template>

      <div class="to-top" id="toTop" @click="scroppToTop">
        <div></div>
        <span>TOP</span>
      </div>

</template>

<style scoped>
.to-top{position: fixed; z-index:999; right:10px; bottom:26px; width:38px; height:38px; border-radius: 5px; display: flex; visibility: hidden; user-select: none; flex-direction: column; justify-content: center; align-items: center; background:#f9f9f9; box-shadow: 1px 1px 4px rgba(0,0,0,.2); cursor:pointer; border:1px solid #f2f2f2; transition-duration: 300ms;}
.to-top:hover{background:#d0e2f8;}
.to-top img{width:36px; height:36px; margin:auto;}
.to-top>div{width:11px; height:11px; border-top:1px solid #939393; border-left:1px solid #939393; transform: rotate(45deg); margin-top:6px;}
.to-top>span{color:#939393; font-size: 12px; line-height: 12px; transform: scale(0.8); margin-top:-2px;}
@media (max-width: 600px) {
.to-top{display:none;}
}
</style>
