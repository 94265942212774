<script setup>
import {ref} from 'vue'
// import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { useCounterStore } from '@/stores/counter'
import SearchBox from '../components/SearchBox2.vue'
import Ad from '../components/Ad.vue'

import {getCompanyNewsList} from '@/api/index';



const store = useCounterStore()
const { t,locale } = useI18n()
// const { count } = store 
// 代替解构赋值，直接解构赋值，属性会失去响应式
// const { count } = storeToRefs(store) 
const rawList = ref([]);
const list = ref([]);

const keyword = ref('')
const search = (e)=>{
   console.log(e)
   let searchList = [];
   keyword.value = e;
   rawList.value.forEach((item)=>{
      console.log(item.company_name)
      if(item.title.indexOf(e)!==-1||item.title_en.indexOf(e)!==-1){
         console.log(item)
         searchList.push(item)
      }
   })
   if(searchList.length===0){
      alert("暂无搜索结果！")
      document.getElementById("clearSearch").click()
      clearSearch()
   }else{
      list.value = searchList
   }
}
const clearSearch = ()=>{
   keyword.value = '';
   list.value = rawList.value;
}

const getList = ()=>{
   getCompanyNewsList({}).then(res=>{
      console.log(res)
      rawList.value = res.data
      list.value = res.data
   })
}
getList();

</script>

<template>
  <div class="page-news">
    <div class="wrap">

      <div class="flexbox">
         <div class="left">
          <SearchBox :msg="keyword" @my-search="search" @clear-search="clearSearch"></SearchBox>
            <div class="newslist">
               <h2>{{ locale==='zh'?"企业新闻-列表":"Company News"}}</h2>
               <ul>
                  <template v-for="(item,index) in list">
                  <li :key="index" v-if="(locale==='zh'&&item.title!=='')||(locale==='en'&&item.title_en!=='')">
                     <div class="flexbox">
                        <RouterLink :to="'/cdetail/'+item.news_id" class="imgbox" v-if="item.image_path!=''">
                           <img :src="item.image_path" />
                        </RouterLink>
                        <div class="textbox">
                           <h4><RouterLink :to="'/cdetail/'+item.news_id" class="ell2">{{ locale==='zh'?item.title:item.title_en }}</RouterLink></h4>
                           <div class="desp"><span>{{ locale==='zh'?"企业":"Company"}}：{{ locale==='zh'?item.company_name:item.company_name_en }}</span><span>{{ locale==='zh'?"发布时间":"Time"}}：{{ item.release_date }}</span></div>
                        </div>
                     </div>
                    <div class="desp-m"><span>{{ locale==='zh'?"企业：":"Company："}}{{ locale==='zh'?item.company_name:item.company_name_en }}</span><span>{{ item.release_date }}</span></div>
                  </li>
                  </template>
               </ul>
            </div>
         </div>
         <div class="right">
            <Ad width="300px" />
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.page-news{width:100vw; background:#fff; min-height:calc( 100vh - 225px ); padding-bottom:30px;}
.page-news .wrap{width:1100px; margin:auto;}
.page-news .flexbox{display:flex; justify-content: space-between;}
.page-news .flexbox .left{width:740px; flex:0 0 740px;}
.page-news .flexbox .left form{margin:35px auto 5px auto;}
.newslist h2{font-size:15px; color:#666; border-bottom:2px solid #666; margin-top:15px;}
.newslist ul{padding:0;}
.newslist ul li{list-style-type: none; padding:15px 0; border-bottom:1px solid #ccc;}
.newslist ul li .flexbox{display:flex; }
.newslist ul li .imgbox{font-size:0; margin-right:15px; height:80px; border:1px solid #ccc;}
.newslist ul li .imgbox img{width:144px; height:90px; object-fit: cover;}
.newslist ul li .textbox{flex:1 1 auto; display: flex; flex-direction: column; justify-content: center;}
.newslist ul li h4{font-size: 16px; font-weight: bold; padding:5px 0;}
.newslist ul li h4 a{color:#042246;}
.newslist ul li h4 a:hover{text-decoration: underline; color:#013678; background:none;}

.newslist ul li .desp{color:#999; display: flex; justify-content: space-between; padding:5px 0;}
.newslist ul li .desp-m{display:none;}


.page-news .flexbox .adbox{margin-top:68px;}


@media (max-width: 600px) {
.page-news{min-height:calc( 100vh - 173px );}

.page-news .wrap{width:414px; margin:auto;  margin-top:30px;}
.page-news .flexbox{display:block;}
.page-news .flexbox .left{width:414px; flex:0 0 414px; padding:0 15px;}

.newslist h2{margin-top:30px;}
.newslist ul li{padding:15px 0 10px 0;}
.newslist ul li .desp{display:none;}
.newslist ul li .desp-m{color:#999; display: flex; justify-content: space-between; padding:5px 0; font-size:13px;}
.newslist ul li .imgbox{font-size:0; margin-right:15px; width:100px; height:58px; border:1px solid #ccc;}
.newslist ul li .imgbox img{width:100px; height:58px; object-fit: cover;}

.page-news .flexbox .adbox{margin-top:38px; padding:0 15px;}

}
</style>